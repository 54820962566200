<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h6>List of MTS/{{dataList.booking_no}}</h6>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('mts').create"
						size="sm" style="margin-right: 10px" 
						color="success" :href="'#/booking/'+$route.params.id+'/create_mts'" target="_blank"
						class="float-lg-right"><i class="fa fa-plus"></i>Add MTS
					</CButton>

					<CButton 
			            size="sm" style="float: right; margin-right: 10px" :class="'float-lg-right block '" 
			        	 @click ="backToTable()"
	    				color="secondary">
						 <i class="fa fa-arrow-left"></i>Back
					</CButton>
		      	
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>	
		
			 
		      <CCol lg="12" >  
				  <div class="table-responsive-sm table-responsive-md">
				<CDataTable 
				    :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
					
			    >  
				
			    	<template #status="{item}">
						 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
						<CBadge 
						 style="margin-top:10px"		
							:color="getBadge(item.status)">{{item.status}}</CBadge>
							 </td>
			      	</template>
			      	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('mts').view" @click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem>  
				            	<CDropdownItem v-if="config.getPermission('mts').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Update </i></CDropdownItem>  
								   <CDropdownItem v-if="config.getPermission('mts').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
				                <!-- <CDropdownItem @click="openApproveModal(item)" v-if="item.status == 'under review'">Approve</CDropdownItem> 
				                <CDropdownItem @click="openDispatchModal(item)" v-if="item.status == 'approved'">Dispatch</CDropdownItem> 
				                <CDropdownItem @click="openDeliverModal(item)" v-if="item.status == 'dispatched'">Deliver</CDropdownItem> 
				                <CDropdownItem @click="openCompleteModal(item)" v-if="item.status == 'delivered'">Complete</CDropdownItem>  -->
				            </CDropdown>
				        </td>
			      	</template>
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />
				</div>
		    </CCol> 
			
	    </CRow> 
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';

export default {
	mounted(){  
		this.getVendor();
		this.getDepot();
		this.getData();  
	},
	data(){
		return{ 
			isLoading: false,
			config,
			formModal: false,
			showViewModal: false,
			dataList: {
				data: [],
				total: 0,
				booking_no: ""
			},  
			



			depotName: "", 
			fields: [
				{
					key: 'mts_no', 
					label: 'MTS No.'
				},
				{
					key: 'origin', 
					label: 'Origin'
				},
				{
					key: 'destination', 
					label: 'Destination'
				},
				{
					key: 'department', 
					label: 'Dept / Proj'
				},
				{
					key: 'ref_no', 
					label: 'Ref No.'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				mts_no: "",
	            origin_id: "",
	            destination_id: "",
	            dept_id: "",
	            ref_no: "", 
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			}
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		reloadData(){
			this.getData();  
		},
		 backToTable(){
	    	this.$router.push('/main/view_booking/'+this.$route.params.id)
	    },
		 getBadge (status) {
    	return status === 'completed' ? 'success'
       		 : status === 'under review' ? 'dark'
         	 : status === 'approved' ? 'warning'
			 : status === 'cancelled' ? 'secondary'
			 : status === 'delivered' ? 'primary'
         	 : status === 'dispatched' ? 'info'
             : status === 'declined' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},     

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(){  
			this.isLoading=true;

	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}
	    	var destination_id = this.filter.destination_id;
	    	if(destination_id == "all" || destination_id == null){
	    		destination_id = ""
	    	} 
	    	var dept_id = this.filter.dept_id;
	    	if(dept_id == "all" || dept_id == null){
	    		dept_id = ""
	    	} 

	    	axios.get(config.api_path+'/materialTransfer/list?mts_no='+this.filter.mts_no+'&ref_no='+this.filter.ref_no+'&origin_id='+origin_id+'&destination_id='+destination_id+'&dept_id='+dept_id+'&page='+this.currentPage+'&limit=20&booking_id='+this.$route.params.id)
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			// if(value.origin_id){
	    			// 	value.origin_id_label = value.origin.customer_name
	    			// }
	    			// else{
	    			// 	value.origin_id_label = "N/A"
	    			// }

	    			// if(value.destination_id){
	    			// 	value.destination_id_label = value.destination.customer_name
	    			// }
	    			// else{
	    			// 	value.destination_id_label = "N/A"
	    			// } 

	    			// if(value.dept_id){
	    			// 	value.dept_id_label = value.dept.customer_name
	    			// }
	    			// else{
	    			// 	value.dept_id_label = "N/A"
	    			// }
					value.origin = value.origin ? value.origin : "N/A"; 
	    			value.destination = value.destination ? value.destination : "N/A"; 
	    			value.dept = value.dept ? value.dept.customer_name : "N/A"; 
	    			value.remarks = value.remarks != "" ? value.remarks : "N/A";
	    			value.prepared_by = value.prepared_by != "" ? value.prepared_by : "N/A";
	    			value.approved_by = value.approved_by != "" ? value.approved_by : "N/A";   
	    			value.transfer_date = value.transfer_date ? moment.unix(value.transfer_date).format('MMMM DD, YYYY') : "N/A"; 
					value.serial_no = value.serial_no ? value.serial_no : 'N/A';
					value.brand = value.brand ? value.brand : 'N/A';
					value.ref_no = value.ref_no ??'' ;

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 

	    updateModal(item){ 
	    	this.$router.push('/booking/'+this.$route.params.id+'/update_mts/'+item.id) 
	    },

	    viewModal(item){ 
	    	this.$router.push('/booking/'+this.$route.params.id+'/view_mts/'+item.id) 
	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this MTS?', 
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/materialTransfer/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "MTS successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    }, 

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})
	    	})

	    },

	    getDepot(){

	    	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
	    	.then(response => {
	    		this.depotList = response.data;
	    		this.depotList.data = this.depotList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.setting_name;
	    			return value;
	    		})
	    	})

	    }, 

	    openApproveModal(item){
	    	this.approveModal = true;
	    	this.selectedRow = item;
	    },

	    openDispatchModal(item){
	    	this.dispatchModal = true;
	    	this.selectedRow = item;
	    },

	    openDeliverModal(item){
	    	this.deliverModal = true;
	    	this.selectedRow = item;
	    },

	    openCompleteModal(item){
	    	this.completeModal = true;
	    	this.selectedRow = item;
	    }, 
		 

 	}
}
</script>
